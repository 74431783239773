import React from "react";
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout";
import Link from "../../components/Link";

const FestPlan = () => {
  return (
    <Layout>
      <h1>
        <i>FestPlan</i>: Support
      </h1>
      <h2>Kontakt</h2>
      <p>Melde dich gern mit deiner Anfrage per E-Mail:</p>
      <Link href="mailto:app.festplan+support@gmail.com">app.festplan+support@gmail.com</Link>
    </Layout>
  );
};

export default FestPlan;
